<template>
  <section class="ps-section--account">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="ps-section__left">
            <aside class="ps-widget--account-dashboard">
              <div class="ps-widget__header">
                <img src="/img/user.12bc27af.png" alt="User" />
                <figure>
                  <figcaption>Hello</figcaption>
                  <p>
                    <a href="#">
                      <span class="__cf_email__">{{ auth.user.name }}</span>
                    </a>
                  </p>
                </figure>
              </div>
              <div id="toggle_collapsible" class="collapsible">
                Show Menu<i
                  id="toggle_no_wrap"
                  class="icon-chevron-down-circle"
                  style="font-size: 13px; margin: 0px 5px"
                ></i>
              </div>
              <div class="ps-widget__content collapse-content">
                <ul>
                  <li class="">
                    <router-link :to="{ name: 'my_profile' }"
                      ><i class="icon-user"></i> Account
                      Information</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'notifications' }"
                      ><i class="icon-alarm-ringing"></i>
                      Notifications</router-link
                    >
                  </li>
                  <li :class="{ active: $route.name === 'UserOrders' }">
                    <router-link :to="{ name: 'my_orders' }"
                      ><i class="icon-papers"></i> Invoices</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'my_addresses' }"
                      ><i class="icon-map-marker"></i> Addresses</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'my_prescriptions' }"
                      ><i class="icon-ambulance"></i> Prescriptions</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'my_runner_requests' }"
                      ><i class="icon-ambulance"></i> Runner
                      Requests</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'my_listings' }"
                      ><i class="icon-list4"></i> Classified
                      Listing</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'my_vouchers' }"
                      ><i class="icon-coin-dollar"></i> Vouchers</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'my_airtime_purchases' }"
                      ><i class="icon-phone-plus"></i> DialFrom263</router-link
                    >
                  </li>
                  <li>
                    <a href="#" @click.prevent="logout()"
                      ><i class="icon-power-switch"></i>Logout</a
                    >
                  </li>
                </ul>
              </div>
              <hr />
            </aside>
          </div>
        </div>
        <div class="col-lg-8">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },

  mounted() {
    this.collapseMenu();
  },
  methods: {
    collapseMenu() {
      var coll = document.getElementsByClassName("collapsible");
      var i;

      for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function () {
          this.classList.toggle("active");
          var content = this.nextElementSibling;
          if (content.style.display === "block") {
            content.style.display = "none";
          } else {
            content.style.display = "block";
          }
        });
      }

      $("#toggle_collapsible").on("click", function () {
        $("#toggle_no_wrap").toggleClass("icon-chevron-down-circle");
        $("#toggle_no_wrap").toggleClass("icon-chevron-up-circle");
      });
    },
  },
};
</script>
<style lang="css" scoped>
.collapsible {
}

.collapse-content {
  display: none;
  overflow: hidden;
}
</style>
